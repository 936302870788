/* eslint-disable no-mixed-operators */
import React, { useEffect } from "react";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import styles from "../styles/container/Container.module.scss";
import mainLogo from "../icons/LOGO.png";
import walletLogo from "../icons/wallet.png";
import { useWeb3Modal } from "@web3modal/react";

const Header = () => {
    const { address, isConnected } = useAccount();
    const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()
    const { chain } = useNetwork();
    const { open } = useWeb3Modal();
    const onConnect = async () => {
        await open();
    };
    useEffect(() => {
        const switchChain = async () => {
            try {
                switchNetwork?.(56)
            } catch (e) {
                console.error(e)
            }
        }
        if (isConnected === true) {
            if ( chain.id !== 56)
                switchChain();
        }
    }, [isConnected, chain, switchNetwork])

    return (
        <div>
            <div className={styles.HeaderContainer}>
                <div className={styles.HeaderContainer}>
                    <section className={styles.BalanceSection}>
                        <img src={mainLogo} alt="logo" />
                    </section>
                    <section className={styles.ButtonContainer}>
                        <div className="connectButtonBox">
                        </div>
                        <div className="connectButtonBox">
                            {!isConnected ?
                                <>
                                    <button className="ConnectButton" type="submit" onClick={() => {
                                        onConnect();
                                    }}><img src={walletLogo} alt="logo" width={'20px'} />
                                        Connect Wallet</button>
                                </>
                                :
                                <section className={styles.ConnectWalletSection}>

                                    <button
                                        className="ConnectButton" type="submit"
                                        onClick={() => onConnect()}
                                    >
                                        <img src={walletLogo} alt="logo" width={'20px'} />
                                        {address.slice(0, 4) + '...' + address.slice(-4)}
                                    </button>

                                </section>
                            }
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Header;